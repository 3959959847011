

import common from '@/mixin/common.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-join',
  mixins: [common],
  data() {
    return {
      mapBgImage: require('@/assets/join/bg.jpg'),
      contentBgImage: require('@/assets/join/content_bg.png'),
      selectDepItem: {},   // 当前选择的部门
      selectAddressItem: {},  // 当前选择的城市
	  pageNum: 1,
	  pageSize: 6,
    }
  },
  created() {
    this.getSelectList();
    this.getJobList();
    // this.scrollToTop();
  },
  methods: {
    ...mapActions('join', ['getSelectList', 'getJobList']),
    showDetail(params) {
      if (!params || !params.code) return;
      this.$router.push(`/join/detail/${params.code}`);
    },
    selectDepTap(params) {
		console.log('选中岗位',params)
      this.selectDepItem = params;
    },
    selectCityTap(params) {
		console.log('选中地区',params)
      this.selectAddressItem = params;
    },
	LoadMoreList(){ //加载更多
		// this.pageNum += 1
		// this.pageSize = 3
		this.pageSize+=3
		this.begainSearch();
	},
    begainSearch() {
	var _this = this;
      this.getJobList({
        city_id: this.selectAddressItem.dictLabel,
        department_id: this.selectDepItem.dictLabel,
		pageNum: _this.pageNum,
		pageSize: _this.pageSize
      })
    },
    buildLocation(ids, zhPageKey) {
      const cityNames = [];
      const countryNames = [];
      for (const id of ids) {
        const city = this.addressList.find(u => u.id === parseInt(id));
        if (!city) {
          continue;
        }
        cityNames.push(city['name' + zhPageKey]);
        const countryName = city.country['name' + zhPageKey];
        if (countryNames.indexOf(countryName) < 0) {
          countryNames.push(countryName);
        }
      }
      return `${cityNames.join(' / ')}<div class="comma">,</div>${countryNames.join(' / ')}`;
    },
  },
  computed: {
    ...mapGetters('join', ['departmentList', 'addressList', 'haveMore', 'jobList']),
    selectDep: function() {
      if (this.selectDepItem) {
        return this.selectDepItem;
      } else {
        return this.$t('join.department');
      }
    },
    selectAddress: function() {
      if (this.selectAddressItem) {
        return this.selectAddressItem;
      } else {
        return this.$t('join.address');
      }
    }
  }
}